@import 'sass';

.Home {
	width: 100%;
	height: 100%;
	min-height: 100%;
	box-sizing: border-box;
	overflow: hidden;

	// -------------------------------------- BOOKING ---------------------------------------------->

	.ModalBackground {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: rgba($color: black, $alpha: 0.5);
		z-index: 5;
	}

	.Booking {
		position: fixed;
		@include flex-center;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 10;
		height: 8%;
		width: 300px;
		padding: 10px 10px 0;
		@media screen and (max-height: $breakpoint-tablet) {
			width: 250px;
		}

		overflow: visible;
	}

	// -------------------------------------- MAIN ---------------------------------------------->

	.Window {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		padding: 0;
		@media screen and (max-height: $breakpoint-small) {
			padding: 5px;
		}
		width: 100%;
		overflow: hidden;

		.Main {
			z-index: 0;
			position: relative;
			height: 100%;
			width: 100%;
			// border-radius: 10px;
			overflow-x: hidden;
			overflow-y: scroll;
			transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);

			section {
				margin: 0 auto;
				min-height: 350px;
				max-width: 2100px;
				@media screen and (max-width: $breakpoint-small) {
					margin: 25px auto;
				}
			}

			// -------------------------------------- TOP BANNER ---------------------------------------------->

			.HomeBanner {
				margin: 0 auto;
				position: relative;
				width: 100%;
				height: 100%;
				// padding: 10px;
				@include flex-center;

				.BackgroundPhoto {
					position: relative;
					height: 100%;
					width: 100%;
					background-image: url('../../images/living_room_3.jpg');
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center 70%;
					// border-radius: 10px;
					// overflow: hidden;

					.Tint {
						z-index: 0;
						position: absolute;
						height: 100%;
						width: 100%;
						@media screen and (max-width: $breakpoint-tablet) {
							border-radius: 0;
						}
						background-color: rgba($color: black, $alpha: 0.3);
					}
				}

				.MainHeader {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: 1;
					width: 100%;
					h1 {
						width: 100%;
						padding: 0 5%;
						text-align: center;
						margin-bottom: 10px;
						// font-family: 'Marcellus', serif;
						// font-family: serif;
						letter-spacing: 1pt;
						font-size: 60px;
						font-weight: 300;
						color: white;
						text-shadow: rgba(0, 0, 0, 0.4) 1px 0px 10px;
						@media screen and (max-width: $breakpoint-tablet) {
							font-size: 40px;
						}
						@media screen and (max-width: $breakpoint-small) {
							font-size: 34px;
						}
						@media screen and (max-width: $breakpoint-mobile) {
							font-size: 32px;
						}
					}
					h3 {
						width: 100%;
						padding: 0 10%;
						text-shadow: rgba(0, 0, 0, 0.4) 1px 0px 20px;
						text-align: center;
						// font-family: 'Marcellus', serif;
						font-size: 28px;
						font-weight: 300;
						color: white;
						letter-spacing: 0.5pt;
						@media screen and (max-width: $breakpoint-tablet) {
							font-size: 20px;
						}
						@media screen and (max-width: $breakpoint-small) {
							font-size: 18px;
						}
						@media screen and (max-width: $breakpoint-mobile) {
							font-size: 16px;
						}
					}
				}

				.Scroll {
					position: absolute;
					bottom: 5%;
					left: 50%;
					transform: translateX(-50%);
					// border: 3px solid red;
					height: 50px;
					width: 50px;
					transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
					color: $color-light-two;
					cursor: pointer;

					.Door {
						position: absolute;
						// top: -100%;
						left: 50%;
						transform: translateX(-50%);
						bottom: 100%;
						@include flex-center;
						div {
							position: absolute;
							bottom: 0;
							font-size: 28px;
							// text-align: center;
							@include flex-center;
							transition: all 1s cubic-bezier(0.39, 0.575, 0.565, 1);
							opacity: 0;
						}

						.Current {
							opacity: 1;
						}
					}

					.Arrow {
						position: absolute;
						top: 10px;
						left: 50%;
						transform: translateX(-50%);
						@include flex-center;
						font-size: 22px;
						// color: $color-dark;

						.Bounce {
							position: absolute;
							top: 0;
							animation: bounce 1.5s infinite;
						}
					}
				}
			}

			// -------------------------------------- INTRO ---------------------------------------------->

			.DescriptionAnimated {
				@media screen and (max-width: $breakpoint-small) {
					margin: 0 auto 40px;
				}
				position: relative;
				height: 45%;
				@media screen and (max-width: $breakpoint-large) {
					height: 50%;
				}
				width: 100%;
				.Welcome {
					z-index: 2;
					position: absolute;
					bottom: 50%;
					right: 50%;
					transform: translate(50%, 50%);
					transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);

					height: auto;
					width: auto;
					min-width: 30%;
					@include flex-center;
					h2 {
						color: $color-aqua;
						text-align: center;
						font-family: 'Great Vibes', cursive;
						font-size: 150px;
						transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
						@media screen and (max-width: $breakpoint-tablet) {
							font-size: 130px;
						}
						@media screen and (max-width: $breakpoint-small) {
							font-size: 100px;
						}
						@media screen and (max-width: $breakpoint-mobile) {
							font-size: 80px;
						}
					}
				}
				.Intro {
					z-index: 1;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
					opacity: 0;

					height: auto;
					width: 50%;
					@media screen and (max-width: $breakpoint-small) {
						width: 80%;
					}
					@include flex-center;
					.InfoBox {
						position: relative;
						height: auto;
						width: 80%;
						max-width: 800px;
						@include flex-center;
						p {
							text-align: justify;
							font-family: 'Marcellus', serif;
							@include flex-center;
							font-size: 24px;
							line-height: 1.4;
							@media screen and (max-width: $breakpoint-large) {
								font-size: 20px;
							}
							@media screen and (max-width: 1300px) {
								font-size: 17px;
							}
							@media screen and (max-width: $breakpoint-tablet) {
								font-size: 16px;
							}
							@media screen and (max-width: $breakpoint-small) {
								font-size: 14px;
							}
							@media screen and (max-width: $breakpoint-mobile) {
								font-size: 12px;
							}
						}
						.ShowMore {
							position: absolute;
							bottom: -35px;
							left: -25px;
							font-size: 18px;
							@media screen and (max-width: $breakpoint-tablet) {
								font-size: 12px;
								bottom: -25px;
								left: -15px;
							}
							font-weight: 300;
							cursor: pointer;
							span {
								font-weight: 300;
							}
						}
					}
				}

				&Play {
					@extend .DescriptionAnimated;
					.Welcome {
						h2 {
							color: $color-dark;
							font-size: 100px;
							@media screen and (max-width: 1300px) {
								font-size: 90px;
							}
							@media screen and (max-width: $breakpoint-tablet) {
								font-size: 80px;
							}
							@media screen and (max-width: 875px) {
								font-size: 70px;
							}
							// vertical stack below breakpoint-small
							@media screen and (max-width: $breakpoint-small) {
								font-size: 70px;
							}
							@media screen and (max-width: $breakpoint-mobile) {
								font-size: 60px;
							}
						}

						@keyframes shiftLeft {
							from {
								right: 50%;
								bottom: 50%;
								transform: translate(50%, 50%);
							}
							to {
								right: 60%;
								bottom: 50%;
								transform: translate(0, 50%);
							}
						}
						@keyframes shiftUp {
							from {
								bottom: 50%;
								right: 50%;
								transform: translate(50%, 50%);
							}
							to {
								bottom: 63%;
								right: 50%;
								transform: translate(50%, 0);
							}
						}

						animation: shiftLeft 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.5s forwards;
						@media screen and (max-width: $breakpoint-small) {
							animation: shiftUp 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.5s forwards;
						}
					}
					.Intro {
						@keyframes shiftRight {
							from {
								opacity: 0;
								left: 50%;
								top: 50%;
								transform: translate(-50%, -50%);
							}
							to {
								opacity: 1;
								left: 40%;
								top: 50%;
								transform: translate(0, -50%);
							}
						}
						@keyframes shiftDown {
							from {
								opacity: 0;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
							}
							to {
								opacity: 1;
								top: 42%;
								left: 50%;
								transform: translate(-50%, 0);
							}
						}
						animation: shiftRight 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.5s forwards;
						@media screen and (max-width: $breakpoint-small) {
							animation: shiftDown 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.5s forwards;
						}
					}
				}
			}

			// -------------------------------------- GALLERY ---------------------------------------------->

			.Gallery {
				position: relative;
				height: 55%;
				width: 100%;
				padding: 20px;
				@media screen and (max-width: $breakpoint-large) {
					height: 50%;
				}
				@media screen and (max-width: 1200px) {
					display: none;
				}
			}

			.GalleryMobile {
				display: none;
				@media screen and (max-width: 1200px) {
					display: block;
					position: relative;
					height: 50%;
					width: 100%;
				}
				@media screen and (max-width: $breakpoint-tablet) {
					height: 50%;
				}
			}

			// -------------------------------------- AMENITIES ---------------------------------------------->

			.AmenitiesWeb {
				@media screen and (max-width: $breakpoint-small), (max-height: 650px) {
					display: none;
				}

				margin-top: 50px;
				position: relative;
				height: 85%;
				width: 100%;
				padding: 3% 5%;
				display: flex;
				flex-direction: column;
				align-items: center;

				.Header {
					position: relative;
					width: 80%;
					top: 0;

					display: flex;
					justify-content: space-between;

					h2 {
						color: $color-dark;
						font-family: 'Great Vibes', 'Marcellus', serif;
						font-size: 92px;
						transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
					}
					h4 {
						margin-top: 5px;
						font-size: 15px;
						font-weight: 300;
						cursor: default;
						border-bottom: 0.5px solid;

						&:hover {
							color: $color-yellow;
						}
					}
				}

				.MatrixContainer {
					position: relative;
					height: 90%;
					width: 100%;

					.Matrix {
						position: absolute;
						height: 100%;
						width: 100%;

						&HoverGrid {
							@extend .Matrix;
							display: grid;
							grid-template-columns: repeat(7, 1fr);
							grid-template-rows: repeat(4, 1fr);

							.GridSlot {
								@include flex-center;

								.HoverDiv {
									width: 100%;
									height: 100%;
								}
							}
						}
					}
				}
			}

			.AmenitiesMobile {
				display: none;
				@media screen and (max-width: $breakpoint-small), (max-height: 650px) {
					display: block;
					position: relative;
					height: 50%;
					width: 100%;

					padding: 5%;
					display: flex;
					flex-direction: column;
					align-items: center;
				}
			}

			// -------------------------------------- HOST ---------------------------------------------->

			.Host {
				position: relative;
				height: 50%;
				width: 100%;
				display: flex;
				justify-content: space-evenly;
				align-items: center;
				padding: 20px;
				@media screen and (max-width: $breakpoint-small) {
					flex-direction: column;
					height: fit-content;
				}

				.HostPhoto {
					position: relative;
					height: 100%;
					width: 30%;
					min-width: 350px;
					border-radius: 10px;
					@media screen and (max-width: $breakpoint-tablet) {
						height: 70%;
						width: 40%;
					}
					@media screen and (max-width: $breakpoint-small) {
						flex-shrink: 0;
						height: 300px;
						width: 400px;
						margin-bottom: 20px;
					}
					@media screen and (max-width: $breakpoint-mobile) {
						flex-shrink: 0;
						height: 250px;
						min-width: 200px;
						width: 80%;
						margin-bottom: 20px;
					}
					background-image: url('../../images/host.jpg');
					background-size: cover;
					background-position: center;
					border: solid $color-light-two;
					border-width: 10px 10px 50px;
					// border-radius: 10px;

					.Signature {
						position: absolute;
						bottom: -30px;
						right: 5%;
						font-family: 'Great Vibes', cursive;
						font-size: 25px;
						@media screen and (max-width: $breakpoint-mobile) {
							font-size: 20px;
						}
						transform: rotateZ(-15deg);
						text-shadow: 2px 2px 5px white;
					}
				}

				.HostIntro {
					position: relative;
					height: auto;
					width: 35%;
					@media screen and (max-width: $breakpoint-small) {
						width: 80%;
					}
					@media screen and (max-width: $breakpoint-mobile) {
						width: 80%;
					}
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					gap: 1rem;
					p {
						margin: 5px;
						text-align: justify;
						font-family: 'Marcellus', serif;
						@include flex-center;
						font-size: 20px;
						line-height: 1.4;
						@media screen and (max-width: $breakpoint-tablet) {
							font-size: 16px;
						}
						@media screen and (max-height: $breakpoint-mobile) {
							font-size: 14px;
						}
					}
				}
			}

			// -------------------------------------- INFO ---------------------------------------------->

			.Info {
				position: relative;
				height: fit-content;
				min-height: 40%;
				width: 100%;
				padding: 3% 5%;
				display: grid;
				grid-template-columns: repeat(3, 25%);
				justify-content: center;
				gap: 5%;
				// padding-top: 50px;
				margin-bottom: 30px;

				@media screen and (max-width: $breakpoint-small) {
					margin-top: 60px;
					margin-bottom: 50px;
					padding: 0 10%;
					display: flex;
					flex-direction: column;
				}

				.Container {
					height: fit-content;
					overflow: hidden;
					display: flex;
					flex-direction: column;
					flex-shrink: 0;

					@media screen and (max-width: $breakpoint-small) {
						margin-bottom: 20px;
					}

					.Tab {
						flex-shrink: 0;
						border-radius: 10px;
						@include flex-center;
						height: 7%;
						overflow: hidden;
						border-bottom: groove;
						border-radius: 10px;
						h4 {
							text-transform: uppercase;
							font-family: 'Marcellus', serif;
							letter-spacing: 1pt;
							font-size: 18px;
						}
					}

					.InfoInner {
						flex-shrink: 0;
						position: relative;
						height: fit-content;
						width: 100%;
						padding: 5%;
						@media screen and (max-width: $breakpoint-small) {
							padding: 5% 0;
						}
						font-family: 'Marcellus', serif;
						text-align: center;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: flex-start;

						.Location {
							font-family: 'Roboto', serif;
							font-weight: 300;
							font-size: 20px;
							line-height: 130%;
							text-align: justify;
							@media screen and (max-width: $breakpoint-tablet) {
								text-align: center;
								font-size: 16px;
							}
						}
						.FiveStars {
							font-family: 'Roboto', serif;
							font-weight: 300;
							font-size: 22px;
							margin-bottom: 10px;

							span {
								color: rgb(255, 255, 133);
								font-size: 20px;
							}
						}

						.HighlightedReviews {
							display: flex;
							flex-direction: column;
							justify-content: space-evenly;
							li {
								width: 100%;
								list-style: none;
								display: flex;
								flex-direction: column;
								align-items: center;
								justify-content: center;
								text-align: center;
								font-size: 20px;
								margin: 10px 0;
								div {
									font-weight: 300;
								}
								.Name {
									margin-right: 10px;
									font-style: italic;
									margin-top: 3px;
									font-size: 14px;
									font-weight: 300;
								}
							}
						}

						.RulesMain {
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							li {
								width: 100%;
								list-style: none;
								display: flex;
								align-items: center;
								justify-content: flex-start;
								margin: 5px 0;
								div {
									// color: $color-dark;
									font-size: 18px;
								}
								h4 {
									text-align: left;
									margin-left: 10px;
									font-family: 'Roboto', serif;
									font-weight: 300;
									font-size: 20px;
								}
							}
						}
					}
					.Expand {
						position: relative;
						width: 100%;
						text-align: end;
						margin-top: 10px;
						font-size: 18px;
						font-weight: 300;
						cursor: pointer;
						span {
							font-weight: 300;
						}
					}
				}

				.Intersected {
					opacity: 0;
					@media screen and (max-width: $breakpoint-small) {
						opacity: 1;
					}
					&1 {
						@extend .Intersected;
						animation: fadeInLift 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0s forwards;
						@media screen and (max-width: $breakpoint-small) {
							animation: none;
						}
					}
					&2 {
						@extend .Intersected;
						animation: fadeInLift 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.25s forwards;
						@media screen and (max-width: $breakpoint-small) {
							animation: none;
						}
					}
					&3 {
						@extend .Intersected;
						animation: fadeInLift 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s forwards;
						@media screen and (max-width: $breakpoint-small) {
							animation: none;
						}
					}
				}
			}

			// Color changes for different scroll positions ------------------------------------------>
			&0 {
				@extend .Main;
				background-color: $color-light;
				.Description {
					.Intro {
						.InfoBox {
							color: black;
						}
					}
					.Welcome {
						h2 {
							color: $color-dark;
						}
					}
				}
				.AmenitiesWeb {
					color: black;
				}
				.Host {
					.HostIntro {
						color: black;
					}
				}
				.Info {
					.Container {
						.Tab {
							h4 {
								color: black;
							}
						}
						.InfoInner {
							color: black;
						}
						.Expand {
							color: black;
						}
					}
				}
			}
			&1 {
				@extend .Main;
				background-color: $color-dark;
				.Description {
					.Intro {
						.InfoBox {
							color: $color-light;
						}
					}
					.Welcome {
						h2 {
							color: $color-aqua;
						}
					}
				}
				.AmenitiesWeb {
					color: white;
					.Header {
						h2 {
							color: white;
							font-weight: 300;
						}
					}
				}
				.Host {
					.HostIntro {
						color: $color-light;
					}
				}
				.Info {
					.Container {
						.Tab {
							h4 {
								color: $color-light;
							}
						}
						.InfoInner {
							color: white;
						}
						.Expand {
							color: white;
						}
					}
				}
			}
			&2 {
				@extend .Main;
				background-color: $color-three;
				.Description {
					.Intro {
						.InfoBox {
							color: black;
						}
					}
					.Welcome {
						h2 {
							color: $color-dark;
						}
					}
				}
				.AmenitiesWeb {
					color: black;
				}
				.Host {
					.HostIntro {
						color: black;
					}
				}
				.Info {
					.Container {
						.Tab {
							h4 {
								color: black;
							}
						}
						.InfoInner {
							color: black;
						}
						.Expand {
							color: black;
						}
					}
				}
			}
		}
	}
	// -------------------------------------- MODALS ---------------------------------------------->

	.Modal {
		@include modal;
		.Additional {
			font-size: 16px;
			list-style: circle;
		}
		.Review {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			border-bottom: 1px solid $color-yellow;
			padding-bottom: 10px;
			div {
				margin: 10px;
			}
			@media screen and (max-width: $breakpoint-small) {
				font-size: 16px;
			}
			.ReviewName {
				width: 100%;
				text-align: left;
				position: relative;
				font-size: 16px;
				font-style: italic;

				@media screen and (max-width: $breakpoint-small) {
					font-size: 14px;
				}
			}
			.ReviewText {
				width: 100%;
				text-align: justify;
			}
		}
		.Map {
			width: 100%;
			height: 70%;
			margin-bottom: 20px;
		}
	}
}
