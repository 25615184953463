@import 'sass';

.AmenitiesMatrix {
	height: 100%;
	width: 100%;

	display: grid;
	grid-template-columns: repeat(7, 1fr);
	grid-template-rows: repeat(4, 1fr);

	.Amenity {
		position: relative;
		@include flex-center;
		flex-direction: column;
		text-align: center;
		.Icon {
			position: relative;
			transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
			font-size: 72px;
			@media screen and (max-width: $breakpoint-large) {
				font-size: 52px;
			}
			@media screen and (max-width: $breakpoint-tablet) {
				font-size: 32px;
			}
		}
		.Description {
			position: relative;
			font-family: 'Roboto', serif;
			font-weight: 100;
			text-align: center;
			max-width: 100%;
			opacity: 0;
			transition: all 0.3s ease-in;
			font-size: 18px;
			@media screen and (max-width: $breakpoint-large) {
				font-size: 16px;
			}
			@media screen and (max-width: $breakpoint-tablet) {
				font-size: 12px;
			}

			&Show {
				@extend .Description;
				opacity: 1;
			}
		}
	}

	.Show {
		.Description {
			opacity: 1;
		}
	}

	.Amen {
		opacity: 0;
		&Show {
			@extend .Amen;
			animation: fadeInLift 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
		}
	}

	&0 {
		@extend .AmenitiesMatrix;
		.Amenity {
			color: $color-dark;
		}
		.Show {
			color: $color-dark;
		}
	}
	&1 {
		@extend .AmenitiesMatrix;
		.Amenity {
			color: white;
		}
		.Show {
			color: $color-yellow;
		}
	}
	&2 {
		@extend .AmenitiesMatrix;
		.Amenity {
			color: black;
		}
		.Show {
			color: $color-dark;
		}
	}
}
