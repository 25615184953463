@import 'sass';

.Book {
	position: relative;
	height: 100%;
	width: 100%;
	@include flex-center;
	gap: 1rem;
	border-radius: 50px;
	overflow: visible;
	background: rgba($color: $color-light-two, $alpha: 1);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	cursor: pointer;

	transition: all 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);

	&:hover {
		background-color: $color-three;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
	}

	.Icon {
		font-size: 25px;
		@media screen and (max-width: $breakpoint-small) {
			font-size: 20px;
		}
		color: rgb(66, 66, 66);
		@include flex-center;
		gap: 1rem;
	}
	h2 {
		color: rgb(66, 66, 66);
		font-family: 'Marcellus', serif;
		font-size: 32px;
		@media screen and (max-height: $breakpoint-tablet) {
			font-size: 28px;
		}
	}

	.BookModal {
		background-color: $color-light-two;
		position: absolute;
		top: 50vh;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 350px;
		@include flex-center;
		border-radius: 20px;
	}
}
